import { GetStaticProps } from 'next';
import { useTranslation } from 'next-i18next';
import { NextSeo } from 'next-seo';
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';
import { AuthForm } from '@wbk/auth';
import AuthLayout from '@/components/layout/AuthLayout';

const LoginPage = () => {
  const { t } = useTranslation();
  return (
    <>
      <NextSeo title={t('common:auth.login')} />
      <AuthLayout>
        <AuthForm title={t('common:auth.login')} />
      </AuthLayout>
    </>
  );
};

export const getStaticProps: GetStaticProps<LayoutProps> = async ({ locale = '' }) => {
  return {
    props: {
      header: {
        type: 'mini',
      },
      footer: {
        type: 'mini',
      },
      ...(await serverSideTranslations(locale, [
        'auth',
        'auth_common',
        'common',
        'tickets',
        'home',
        'event',
      ])),
    },
  };
};

export default LoginPage;
